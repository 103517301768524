import axios from 'axios'
import './api'

export const askService = {
  create,
  verify,
  submitForm,
  uploadFiles,
}

async function create(datas) {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/ask`, JSON.stringify(datas))
  if (res.status === 200) {
    return { ...datas, askId: res.data.id }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function verify(hash) {
  // no JWT
  const response = await fetch(`${process.env.REACT_APP_API_URL}/verify/form/${hash}`)
  const result = await response.json()
  return result
}

async function submitForm(hash, data) {
  // no JWT
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ status: data.status }),
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}/form/${hash}`, requestOptions)
  const result = await response.json()
  return result
}

async function uploadFiles(hash, files) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files[]', file, file.name)
  })

  const res = await fetch(`${process.env.REACT_APP_API_URL}/form/${hash}/files`, { method: 'POST', body: formData })

  if (res?.status === 200) {
    const result = await res.json()
    return result
  } else {
    return { success: false }
  }
}
