import React from 'react'
import 'moment/locale/fr'
import moment from 'moment'
import SimpleBar from 'simplebar-react'
import { useDispatch } from 'react-redux'
import Button from 'components/buttons/Button'
import { noticesActions, modalActions } from '_actions'
import { IconDate, IconJoin, IconLike, IconComment, IconDelete, IconDownload } from '_svg'

export default function MediaDetails({ ...modalProps }) {
  const dispatch = useDispatch()

  const getHashtags = () => {
    if (!modalProps.asset[0].hashtags) return
    return modalProps.asset[0].hashtags.map((el, i) => {
      return <span key={`${el}${i}`}>{el.startsWith('#') ? el : `#${el}`}</span>
    })
  }

  const downloadImage = () => {
    dispatch(noticesActions.download([modalProps.asset[0]]))
  }

  const deleteAsset = () => {
    dispatch(modalActions.deleteFromLibrary(modalProps.asset))
  }

  const handleAddToCol = () => {
    dispatch(modalActions.addToCollection(modalProps.asset))
  }

  return (
    <div className="modal-media-details">
      <div style={{ backgroundImage: `url('${modalProps.asset[0].path}')` }} alt="" className="visual">
        {modalProps.asset[0].type === 'VIDEO' && (
          <video controls>
            <source src={modalProps.asset[0].path} type="video/mp4" />
          </video>
        )}

        <div className="ctas">
          <button className="bt-delete" onClick={deleteAsset}>
            <IconDelete className="icon" />
          </button>
          <button className="bt-download mt-4" onClick={downloadImage}>
            <IconDownload className="icon" />
          </button>
        </div>
      </div>
      <div className="modal-media-details__content">
        <div>
          <div className="modal__description">
            {/*                         <span className="user-name text-gradient text-gradient--H">{asset.user_name}</span>
             */}{' '}
            {modalProps.asset[0].caption}
          </div>

          <div className="media-stats mt-4">
            <div className="media-likes">
              <IconLike className="icon" />
              <span className="media-stats-label">{modalProps.asset[0].likeCount} likes</span>
            </div>
            <div className="media-comment">
              <IconComment className="icon icon--grey-light" />
              <span className="media-stats-label">{modalProps.asset[0].commentsCount} commentaires</span>
            </div>
          </div>
          <span style={{ textTransform: "initial", color: "#7b7b7b", marginTop: "20px", display: "inline-flex", "align-items": "center" }}>
            <IconDate className="icon" style={{marginRight: "4px"}} />
            Droits accordés le {moment(modalProps.asset[0].created_at).format('DD.MM.YYYY')} à {moment(modalProps.asset[0].created_at).format('H:m')}
          </span>

          <div className="media-hashtags mt-4">{getHashtags()}</div>
        </div>

        <div className="collections-info">
          {modalProps.asset[0].collections.length > 0 && (
            <>
              <div className="collecion-info-title">Collections associées</div>
              <SimpleBar autoHide={false} style={{ maxHeight: 60, marginTop: 8 }}>
                <ul className="list-reset">
                  {modalProps.asset[0].collections.map((el, i) => {
                    return <li key={i}>{el.name}</li>
                  })}
                </ul>
              </SimpleBar>
            </>
          )}

          {modalProps.asset[0].collections.length <= 0 && <>Ce média n’est associé à aucune collection.</>}
        </div>

        <div className="ctas flex flex-col">
          <Button btnStyle="plain" addedclass="button-icon" onClickFn={handleAddToCol}>
            <IconJoin className="icon" />
            Associer à une collection
          </Button>
          {/*                     <Button btnStyle="border" addedclass="button--border--grey bg-light button-icon mt-2"><IconWidgets className="icon icon--grey" />Ajouter à un widget</Button>
           */}{' '}
        </div>
      </div>
    </div>
  )
}
