import 'moment/locale/fr'
import moment from 'moment'
import Button from 'components/buttons/Button'
import React, { useState } from 'react'
import CheckBox from 'components/filters/CheckBox'
import useSingleton from 'components/utils/useSingleton'
import { IconDelete, IconInsta, IconSend, IconDate, IconLike, IconComment, IconDiapo, IconDownload } from '_svg'
import { useDispatch, useSelector } from 'react-redux'
import { feedService } from '_services'
import { modalActions, noticesActions } from '_actions'

export default function MediasListItem({ data, selectFunction, isSelected = false, rejectFn, askFn }) {
  const dispatch = useDispatch()

  const auth = useSelector((state) => state.authentication)
  const account = useSelector((state) => state.account)
  const [hasAsk, setHasAsk] = useState(false)

  useSingleton(() => {
    // will always be triggered once
    setHasAsk(data.hasAsk)
  })

  const getHashtags = () => {
    if (!data.hashtags) return
    return data.hashtags.map((el, i) => (el.startsWith('#') ? el : `#${el} `))
  }

  const handleSelection = (e) => {
    selectFunction(data, e.checked)
  }

  const handleReject = () => {
    rejectFn(data)
  }

  const handleAsk = () => {
    askFn(data)
  }

  const handleSelfAsk = () => {
    feedService.saveSelfAssets(data.feedId, [data.id]).then((result) => {
      dispatch(noticesActions.postSaved())
      dispatch(modalActions.downloadToCollection(result.assets))
    })
  }

  const getMedia = () => {
    if (data.mediaType === 'VIDEO') {
      return getVideo(data.mediaUrl, data.posterUrl)
    } else if (data.mediaType === 'IMAGE') {
      return getImage(data.mediaUrl)
    } else if (data.mediaType === 'SIDECAR') {
      const children = JSON.parse(data.children)
      if (children[0].media_type === 'IMAGE') {
        return getImage(children[0].media_url, true)
      } else if (children[0].media_type === 'VIDEO') {
        return getVideo(children[0].media_url, children[0].poster_url, true)
      }
    }
  }

  const getImage = (media_url, isDiapo = false) => {
    return (
      <div
        className="thumb"
        style={{ backgroundImage: `url('https://instagram.colegram.workers.dev/?feed_id=${data.feedId}&image_url=${btoa(media_url)}')` }}
      // onClick={(e) => {
      //   handleAsk()
      // }}
      >
        {isDiapo && (
          <>
            <IconDiapo className="icon" style={{ top: 10 }} />
          </>
        )}
        {(account.instagram_usernames ?? []).includes(data.authorUsername) || (account.instagram_hashtags ?? []).filter(value => data.hashtags.includes(value)).length > 0 ?
          <img src={auth.user && auth.user.img ? auth.user.img : '/assets/images/default-profile.jpg'} alt="" style={{ position: "absolute", width: 25, bottom: 10, left: 10, borderRadius: "5px" }} />
          : <></>}
      </div>
    )
  }

  const getVideo = (media_url, poster_url, isDiapo = false) => {
    return (
      <div
        className="thumb"
        onClick={(e) => {
          handleAsk()
        }}
      >
        <video controls muted loop poster={`https://instagram.colegram.workers.dev/?feed_id=${data.feedId}&image_url=${btoa(poster_url)}`}>
          <source src={`https://instagram.colegram.workers.dev/?feed_id=${data.feedId}&video_url=${btoa(media_url)}`} type="video/mp4" />
        </video>
        {isDiapo && <IconDiapo className="icon" />}
        {(account.instagram_usernames ?? []).includes(data.authorUsername) || (account.instagram_hashtags ?? []).filter(value => data.hashtags.includes(value)).length > 0 ?
          <img src={auth.user && auth.user.img ? auth.user.img : '/assets/images/default-profile.jpg'} alt="" style={{ position: "absolute", width: 25, bottom: 10, left: 10, borderRadius: "5px" }} />
          : <></>}
      </div>
    )
  }

  return (
    <div className="feed-medias-list__item">
      <CheckBox name="actions" customClass="select-checkbox" callback={handleSelection} isChecked={isSelected} />
      {getMedia()}
      <div className="content">
        <div className="content--top">
          <span className="media-username">@{data.authorUsername}</span>
          {data.caption}
        </div>
        <div className="content--bottom">
          <div className="media-hashtags mt-2"><span>{getHashtags()}</span></div>
          <div className="media-stats">
            <div className="media-date">
              <IconDate className="icon" />
              <span className="media-stats-label">
                {moment(data.timestamp * 1000)
                  .locale('fr')
                  .format('L')}
              </span>
            </div>
          </div>
          <div className="media-stats">
            <div className="media-likes">
              <IconLike className="icon" />
              <span className="media-stats-label">{data.likeCount} likes</span>
            </div>
            <div className="media-comment">
              <IconComment className="icon icon--grey-light" />
              <span className="media-stats-label">{data.commentsCount} commentaires</span>
            </div>
          </div>
        </div>
      </div>
      <div className="ctas">
        <Button
          btnStyle="border"
          color="grey"
          type={null}
          addedclass="button-icon button--black"
          onClickFn={(e) => {
            handleReject()
          }}
        >
          <IconDelete className="icon" />
          Rejeter du feed
        </Button>
        <Button
          btnStyle="border"
          color="grey"
          type={null}
          addedclass="button-icon button--black"
          url={data.permalink}
          target="_blank"
        >
          <IconInsta className="icon" />
          Voir sur instagram
        </Button>
        {(account.instagram_usernames ?? []).includes(data.authorUsername) || (account.instagram_hashtags ?? []).filter(value => data.hashtags.includes(value)).length > 0 ?
          <Button
            btnStyle="plain"
            addedclass="button-icon"
            onClickFn={(e) => {
              handleSelfAsk()
            }}
          >
            <IconDownload className="icon icon--white" />
            Télécharger
          </Button>
          :
          <>
            {hasAsk ? (
              <Button
                btnStyle="plain"
                color={'grey'}
                addedclass="button-icon"
                onClickFn={(e) => {
                  handleAsk()
                }}
              >
                <IconSend className="icon" />
                Renvoyez une demande
              </Button>
            ) : (
              <Button
                btnStyle="plain"
                addedclass="button-icon"
                onClickFn={(e) => {
                  handleAsk()
                }}
              >
                <IconSend className="icon" />
                demande de droit
              </Button>
            )}
          </>
        }
      </div>
    </div>
  )
}
