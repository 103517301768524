export const accountConstants = {
  GET_ALL: 'ACCOUNT_GET_ALL',
  GET: 'ACCOUNT_GET',
  GET_SELF_ACCOUNT: 'ACCOUNT_GET_SELF',
  UPDATE_CONFIG: 'ACCOUNT_UPDATE_CONFIG',
  CREATE: 'ACCOUNT_CREATE',
  DELETE: 'ACCOUNT_DELETE',
  UPDATE: 'ACCOUNT_UPDATE',
  FAIL: 'ACCOUNT_FAIL',
}

export const PLAN = {
  LIGHT: '1',
  PRO: '2',
  ULTRA: '3',
}

export const FEED_QUOTA = {
  PLAN_LIGHT: 5,
  PLAN_PRO: 20,
  PLAN_ULTRA_CHILDREN: 5,
}

export const WIDGET_QUOTA = {
  PLAN_LIGHT: 3,
  PLAN_PRO: 5,
  PLAN_ULTRA_CHILDREN: 5,
}

export const USER_QUOTA = {
  PLAN_LIGHT: 1,
  PLAN_PRO: 5,
}
