import SelectModule from 'components/filters/SelectModule'
import SearchModule from 'components/filters/SearchModule'
import CheckBoxesModule from 'components/filters/CheckBoxesModule'
import Button from 'components/buttons/Button'
import RadiosModule from 'components/filters/RadiosModule'
import { langs } from '_helpers'

export default function Filters({
  searchFn,
  chbxFilterFn,
  /* hashtagFn, hashtags = [], */
  askStatusFn = null,
  selfMediaFn = null,
  handleAddManuallyClick = null,
  languages = null,
  languageFn = null
}) {
  /* const getHashtags = () => {
        return hashtags.map((el)=>{
            return {label: el, value: el}
        })
    } */

  return (
    <div className='filters'>
      <div className='flex filters__first-row'>
        <div className='filters__search'>
          <SearchModule callback={searchFn} placeholder="Expression, hashtag..." />
        </div>
        {languages && (
          <SelectModule
            title="Langues"
            items={[{
              "label": "Tous",
              "value": null
            }, ...languages.map(language => {
              return {
                "label": langs[language.split("-")[0]] ?? language,
                "value": language
              }
            })]}
            callback={languageFn}
          />
        )}
        {askStatusFn && (
          <SelectModule
            title="Levée de droits"
            items={[
              { label: 'toutes', value: 'both' },
              { label: 'Renvoyez une demande', value: true },
              { label: 'demande non envoyée', value: false },
            ]}
            callback={askStatusFn}
          />
        )}
      </div>
      <div className='flex filters__second-row mb-6 mt-4'>
        <div className='flex'>
          <CheckBoxesModule
            title="types de contenu"
            items={[
              { label: 'Photo', value: 'IMAGE' },
              { label: 'Vidéo', value: 'VIDEO' },
              { label: 'Carousel', value: 'SIDECAR' },
            ]}
            callback={chbxFilterFn}
            flex={true}
          />
          <div>
            <div className="sup-title sup-title--XS sup-title--grey-light">{"Mes médias"}</div>
            <RadiosModule
              callback={selfMediaFn}
              light={false}
              flex={true}
              data={[
                { label: 'Avec mes médias', value: 'both' },
                { label: 'Seulement mes médias', value: 'selfOnly' },
                { label: 'Sans mes médias', value: 'otherOnly' },
              ]} />
          </div>
        </div>
        {handleAddManuallyClick &&
          <Button
            btnStyle="plain"
            onClickFn={handleAddManuallyClick}
          >
            Ajouter un média manuellement
          </Button>
        }
      </div>
    </div>
  )
}
