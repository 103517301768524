import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'
import { Select } from 'antd'
import Layout from 'components/layout/Layout'
import PageHeader from 'components/layout/PageHeader'
import { accountActions, feedActions } from '_actions/'
import Button from 'components/buttons/Button'
import StatusRadio from 'components/buttons/StatusRadio'
import CheckBox from 'components/filters/CheckBox'
import HashtagsList from 'components/filters/HashtagsList'
import { PLAN } from '_constants'

function FeedCreation() {
  const dispatch = useDispatch()
  const alert = useSelector((state) => state.alert)
  const account = useSelector((state) => state.account)
  const { Option } = Select
  const NativeDatepicker = require('native-datepicker/src/react')
  const [isLoading, setIsLoading] = useState(false)
  const [labels, setLabels] = useState({
    label: "",
    placeholder: ""
  })
  const [feed, setFeed] = useState({
    name: null,
    locale: null,
    mediaNumber: 200,
    status: '1',
    hashtags: [],
    type: 'hashtag',
    location_type: null,
    description: null,
    notification_enabled: true,
    blacklisted_words: account.instagram_bannedWords,
    start_at: new Date().toISOString().split('T')[0],
    end_at: moment(moment().add(6, 'M').toDate()).format('YYYY-MM-DD'),
  })

  useEffect(() => {
    checkDates()
  }, [feed.start_at, feed.end_at])

  useEffect(() => {
    if (Object.keys(account).length === 0) {
      dispatch(accountActions.getSelf())
    }
  }, [account])

  useEffect(() => {
    if (feed.type === "hashtag") {
      setLabels({
        label: "Hashtags",
        placeholder: "Renseigner un hashtag"
      })
    }
    else if (feed.type === "location") {
      setLabels({
        label: "Localisation",
        placeholder: "Renseigner une localisation"
      })
    }
    else if (feed.type === "username") {
      setLabels({
        label: "Username",
        placeholder: "Renseigner un username"
      })
    }
    else if (feed.type === "tagged") {
      setLabels({
        label: "Username",
        placeholder: "Renseigner un username"
      })
    }

    setFeed({ ...feed, hashtags: [] })
  }, [feed.type])

  useEffect(() => {
    if (alert.message !== '') setIsLoading(false)
  }, [alert])

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(feedActions.createFeed(feed))
  }

  const checkDates = () => {
    const dateStart = feed.start_at
    const dateEnd = feed.end_at
    if (feed.status === '0') {
      if (
        moment(dateStart).isAfter(dateEnd) /* || moment(dateEnd).isBefore(dateStart) */ ||
        moment(dateStart).isSame(dateEnd)
      ) {
        let nextDay = moment(dateStart).add(1, 'd').toDate()
        setFeed((feed) => {
          return { ...feed, ...{ end_at: moment(nextDay).format('YYYY-MM-DD') } }
        })
      }
    } else if (feed.status === '1') {
      const today = moment().format('YYYY-MM-DD')
      const tomorrow = moment(today).add(1, 'd').toDate()
      if (moment(dateEnd).isBefore(today) || moment(dateEnd).isSame(today)) {
        setFeed((feed) => {
          return { ...feed, ...{ start_at: today, end_at: moment(tomorrow).format('YYYY-MM-DD') } }
        })
      }
    }
  }

  const changeStatus = (value) => {
    setFeed((feed) => {
      return { ...feed, ...{ status: value } }
    })
  }

  const updateBannedHastags = (hashtags) => {
    setFeed({ ...feed, ...{ blacklisted_words: hashtags } })
  }

  return (
    <Layout className="page page-feeds-creation">
      <PageHeader data={[{ label: 'Feed', url: '/feed' }, { label: 'Créer un feed' }]} />
      <div className="page__content">
        <div className="page__intro">
          Configurez votre feed en remplissant les champs ci dessous. Colegram fait le reste !
        </div>
        <div className="fluid-grid">
          <form action="" onSubmit={submitForm}>
            <div className="row mt-2">
              <div className="col-12 md-col-6">
                <div className="text-24">Général</div>
                <div className="mt-2">
                  <label htmlFor="name" className="label">
                    Nom de votre feed*
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="text-input"
                    placeholder="Nom*"
                    required
                    onChange={(e) => {
                      setFeed({ ...feed, ...{ name: e.currentTarget.value } })
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 md-col-6">
                <label htmlFor="status" className="label">
                  Statut du feed
                </label>
                <div className="flex">
                  <StatusRadio
                    value="1"
                    label="Actif"
                    onChangeStatus={changeStatus}
                    isDefaultChecked={feed.status === '1'}
                  />
                  <StatusRadio
                    value="0"
                    label="Planifié"
                    onChangeStatus={changeStatus}
                    isDefaultChecked={feed.status === '0'}
                  />
                  {/* <StatusRadio
                    value="-1"
                    label="Inactif"
                    onChangeStatus={changeStatus}
                    isDefaultChecked={feed.status === '-1'}
                  /> */}
                </div>
              </div>
              <div className="col-12 md-col-6">
                {feed.status === '0' && ( // if planned
                  <label htmlFor="" className="label">
                    Date de début
                  </label>
                )}
                {feed.status === '0' && ( // if planned
                  <NativeDatepicker
                    value={feed.start_at}
                    onChange={(newValue) => {
                      setFeed((feed) => {
                        return { ...feed, ...{ start_at: newValue } }
                      })
                    }}
                  >
                    <input
                      id=""
                      name=""
                      readOnly
                      type="text"
                      className="text-input"
                      value={moment(feed.start_at).locale('fr').format('L')}
                      placeholder="Choisissez une date de début*"
                    />
                  </NativeDatepicker>
                )}

                {/* <label htmlFor="" className="label">
                  Date de fin
                </label>
                <NativeDatepicker
                  value={feed.end_at}
                  onChange={(newValue) => {
                    setFeed((feed) => {
                      return { ...feed, ...{ end_at: newValue } }
                    })
                  }}
                >
                  <input
                    type="text"
                    name=""
                    id=""
                    className="text-input"
                    readOnly
                    value={moment(feed.end_at).locale('fr').format('L')}
                    placeholder="Choisissez une date de fin*"
                  />
                </NativeDatepicker> */}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 md-col-6">
                <label htmlFor="type" className="label">
                  Type de feed
                </label>
                <Select
                  name="type"
                  defaultValue={"hashtag"}
                  onChange={(value) => {
                    if (value === "location") {
                      setFeed({
                        ...feed,
                        type: value,
                        hashtags: [feed.hashtags[0] ?? ''],
                      })
                    } else {
                      setFeed({
                        ...feed,
                        type: value,
                        hashtags: [feed.hashtags[0] ?? ''],
                        location_type: null
                      })
                    }
                  }}
                >
                  <Option value="hashtag">Hashtag</Option>
                  <Option value="location">Localisation</Option>
                  <Option value="username">Username</Option>
                  <Option value="tagged">User taggé</Option>
                </Select>
              </div>
            </div>
            {feed.type === "location" ?
              <div className="row mt-2">
                <div className="col-12 md-col-6">
                  <label htmlFor="location_type" className="label">
                    Type de localisation
                  </label>
                  <Select
                    name="location_type"
                    onChange={(value) => {
                      setFeed({
                        ...feed,
                        location_type: value
                      })
                    }}
                  >
                    <Option value="precise">Précise</Option>
                    <Option value="wide">Large</Option>
                  </Select>
                </div>
              </div>
              : <></>}
            <div className="row mt-2">
              <div className="col-12 md-col-6">
                {account.plan ?
                  <>
                    {feed.type === "hashtag" ?
                      <HashtagsList title={labels.label} theme={'dark'} preselection={feed.hashtags} hashtagsCount={feed.hashtags_count} limit={account.plan !== PLAN.LIGHT ? 3 : 1} callback={hashtags => {
                        setFeed({ ...feed, hashtags: hashtags.filter(h => h !== "") })
                      }} />
                      :
                      <>
                        <label htmlFor="type" className="label">
                          {labels.label}
                        </label>
                        <input
                          type="text"
                          name=""
                          id=""
                          className="text-input"
                          value={feed.hashtags[0] ?? ""}
                          placeholder={labels.placeholder}
                          onChange={(e) => {
                            setFeed({ ...feed, hashtags: [e.currentTarget.value] })
                          }}
                          required
                        />
                      </>
                    }
                  </>
                  : <></>}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 md-col-6">
                <HashtagsList title={'mots à banir'} theme={'dark'} preselection={feed.blacklisted_words} hashtagsCount={feed.hashtags_count} callback={updateBannedHastags} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 md-col-6">
                <label htmlFor="type" className="label">
                  Nombre de médias maximum
                </label>
                <Select
                  name="mediaNumber"
                  defaultValue="200"
                  onChange={(value) => {
                    setFeed({ ...feed, mediaNumber: value })
                  }}
                >
                  <Option value="20">20</Option>
                  <Option value="200">200</Option>
                  <Option value="500">500</Option>
                  <Option value="1000">1000</Option>
                  <Option value="1500">1500</Option>
                  <Option value="2000">2000</Option>
                </Select>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-12 md-col-6">
                <CheckBox
                  isChecked={feed.notification_enabled}
                  label="Je souhaite être notifié des nouveaux médias ajoutés"
                  callback={() => setFeed({ ...feed, ...{ notification_enabled: !feed.notification_enabled } })}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 md-col-6">
                &nbsp;
                {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              </div>
              <div className="col-12 md-col-6 flex flex-col justify-end items-end">
                <Button
                  type="submit"
                  btnStyle="plain"
                  loading={isLoading}
                  disabled={isLoading}
                  color={isLoading ? 'grey' : 'red'}
                  addedclass={feed.hashtags.length <= 0 || isLoading ? 'is-disabled' : ''}
                >
                  Créer le feed
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default FeedCreation
