import axios from 'axios'
import './api'

export const widgetsService = {
  getAll,
  create,
  deleteWidget,
  updateWidget,
}

function getParams(options) {
  if (!options) return ''
  let params = ''
  for (const [key, value] of Object.entries(options)) {
    params += `${key}=${value}&`
  }
  return params.slice(0, -1)
}

async function getAll(options) {
  const params = getParams(options)
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/widget?${params}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function create(datas) {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/widget`, JSON.stringify(datas))
  if (res.status === 200) {
    const defaultProps = {
      id: res.data.id,
      widget: res.data.widget,
      /* assets_count: 0,
            memory: 0 */
    }
    return { ...datas, ...defaultProps }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteWidget(widgetsId) {
  const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/widget/${widgetsId}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateWidget(widget) {
  const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/widget`, JSON.stringify(widget))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}
