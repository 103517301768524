import axios from 'axios'
import './api'

export const feedService = {
  getAll,
  create,
  updateFeed,
  getAsset,
  saveSelfAssets,
  getAllAssets,
  getAssets,
  deleteFeed,
  deleteFeeds,
  deleteAsset,
  manuallyAddMedia,
}

function getParams(options) {
  if (!options) return ''
  let params = ''
  for (const [key, value] of Object.entries(options)) {
    params += `${key}=${value}&`
  }
  return params.slice(0, -1)
}

async function getAll(options) {
  const params = getParams(options)
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/feed?${params}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function create(datas) {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/feed`, JSON.stringify(datas))
  if (res.status === 200) {
    const defaultProps = {
      id: res.data.id,
      assets_count: 0,
      memory: 0,
    }
    return { ...res.data, ...datas, ...defaultProps }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateFeed(feed) {
  const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/feed`, JSON.stringify(feed))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}


async function deleteFeed(feedId) {
  const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/feed/${feedId}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteFeeds(feeds) {
  const body = { feeds: feeds.map((a) => a.id) }
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/feed/delete-batch`, body)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function getAssets(feedId, options) {
  const params = getParams(options)
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/feed/${feedId}/assets?${params}`)
  if (res.status === 200) {
    return { assets: Object.values(res.data.data), pagination: res.data.pagination, feedId: feedId }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function getAllAssets(options) {
  const params = getParams(options)
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/feed/assets?${params}`)
  if (res.status === 200) {
    return { assets: Object.values(res.data.data), pagination: res.data.pagination, feedsData: res.data.feedsData }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function getAsset(feedId, assetId) {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/feed/${feedId}/assets/${assetId}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function saveSelfAssets(feedId, assets) {
  const body = { assets: assets }
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/feed/${feedId}/save-self`, body)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteAsset(assets, feedId) {
  const body = { assets: assets.map((a) => a.id) }
  // const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/feed/${feedId}/assets/${mediaId}`)
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/feed/${feedId}/assets/exclude`, body)
  if (res.status === 200) {
    return { excluded_assets: res.data.excluded_assets, feedId: feedId }
  } else {
    console.log(res.status, res.statusText)
  }
  // return {mediaId, feedId}
}

async function manuallyAddMedia(feedId, url) {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/feed/${feedId}/assets/add`, { url })

  if (res.status === 200) {
    return res.data
  }
}
