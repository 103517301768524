import React from 'react'

import { useSelector } from 'react-redux'

import { noticesConstants } from '_constants'
import * as Notices from 'components/notices/'

export default function NoticeRoot() {
  const NOTICE_COMPONENTS = {}
  NOTICE_COMPONENTS[noticesConstants.DOWNLOAD] = Notices.Download
  NOTICE_COMPONENTS[noticesConstants.DOWNLOAD_CSV] = Notices.DownloadCSV
  NOTICE_COMPONENTS[noticesConstants.COLLECTION_SHARED] = Notices.CollectionShared
  NOTICE_COMPONENTS[noticesConstants.COLLECTION_CREATED] = Notices.CollectionCreated
  NOTICE_COMPONENTS[noticesConstants.COLLECTION_DELETED] = Notices.CollectionDeleted
  NOTICE_COMPONENTS[noticesConstants.ADDED_TO_COLLECTION] = Notices.AddedToCollection
  NOTICE_COMPONENTS[noticesConstants.DELETED_FROM_COLLECTION] = Notices.DeletedFromCollection
  NOTICE_COMPONENTS[noticesConstants.DELETED_FROM_LIBRARY] = Notices.DeletedFromLibrary
  NOTICE_COMPONENTS[noticesConstants.REJECTED_FROM_FEED] = Notices.RejectedFromFeed
  NOTICE_COMPONENTS[noticesConstants.FEED_CREATED] = Notices.FeedCreated
  NOTICE_COMPONENTS[noticesConstants.FEED_DELETED] = Notices.FeedDeleted
  NOTICE_COMPONENTS[noticesConstants.FEED_UPDATED] = Notices.FeedUpdated
  NOTICE_COMPONENTS[noticesConstants.CREATED_MESSAGE] = Notices.MessageCreated
  NOTICE_COMPONENTS[noticesConstants.DELETED_MESSAGE] = Notices.MessageDeleted
  NOTICE_COMPONENTS[noticesConstants.UPDATED_MESSAGE] = Notices.MessageUpdated
  NOTICE_COMPONENTS[noticesConstants.ASK_COPIED] = Notices.AskCopied
  NOTICE_COMPONENTS[noticesConstants.PROFILE_UPDATED] = Notices.ProfileUpdated
  NOTICE_COMPONENTS[noticesConstants.PASSWORD_UPDATED] = Notices.PasswordUpdated
  NOTICE_COMPONENTS[noticesConstants.PASSWORD_RESET] = Notices.PasswordReset
  NOTICE_COMPONENTS[noticesConstants.WIDGET_CREATED] = Notices.WidgetCreated
  NOTICE_COMPONENTS[noticesConstants.WIDGET_UPDATED] = Notices.WidgetUpdated
  NOTICE_COMPONENTS[noticesConstants.WIDGET_DELETED] = Notices.WidgetDeleted
  NOTICE_COMPONENTS[noticesConstants.FETCHING_POST] = Notices.FetchingPost
  NOTICE_COMPONENTS[noticesConstants.POST_SAVED] = Notices.PostSaved

  const getNotices = useSelector((state) => {
    if (!state.notices) return null
    return state.notices.map((el, i) => {
      const NoticeContent = NOTICE_COMPONENTS[el.noticeType]
      return (
        <Notices.Root key={i} noticeId={el.noticeProps.id}>
          <NoticeContent {...el.noticeProps} />
        </Notices.Root>
      )
    })
  })

  return <div className="notices">{getNotices}</div>
}
