export const noticesConstants = {
  OPEN_NOTICE: 'NOTICE_OPEN',
  CLOSE_NOTICE: 'NOTICE_CLOSE',
  AUTOCLOSE_TIMER: 4000,
  DOWNLOAD: 'NOTICE_DOWLOAD',
  DOWNLOAD_CSV: 'NOTICE_DOWNLOAD_CSV',
  COLLECTION_CREATED: 'NOTICE_COLLECTION_CREATED',
  COLLECTION_DELETED: 'NOTICE_COLLECTION_DELETED',
  ADDED_TO_COLLECTION: 'NOTICE_ADDED_TO_COLLECTION',
  DELETED_FROM_COLLECTION: 'NOTICE_DELETED_FROM_COLLECTION',
  DELETED_FROM_LIBRARY: 'NOTICE_DELETED_FROM_LIBRARY',
  TAG_ADDED: 'NOTICE_TAG_ADDED',
  REJECTED_FROM_FEED: 'NOTICE_REJECTED_FROM_FEED',
  FEED_CREATED: 'NOTICE_FEED_CREATED',
  FEED_DELETED: 'NOTICE_FEED_DELETED',
  FEED_UPDATED: 'NOTICE_FEED_UPDATED',
  CREATED_MESSAGE: 'NOTICE_CREATED_MESSAGE',
  DELETED_MESSAGE: 'NOTICE_DELETED_MESSAGE',
  UPDATED_MESSAGE: 'NOTICE_UPDATED_MESSAGE',
  ASK_COPIED: 'NOTICE_ASK_COPIED',
  PROFILE_UPDATED: 'NOTICE_PROFILE_UPDATED',
  PASSWORD_UPDATED: 'NOTICE_PASSWORD_UPDATED',
  PASSWORD_RESET: 'NOTICE_PASSWORD_RESET',
  WIDGET_CREATED: 'NOTICE_WIDGET_CREATED',
  WIDGET_UPDATED: 'NOTICE_WIDGET_UPDATED',
  WIDGET_DELETED: 'NOTICE_WIDGET_DELETED',
  FETCHING_POST: 'NOTICE_FETCHING_POST',
  POST_SAVED: 'NOTICE_POST_SAVED',
  COLLECTION_SHARED: 'NOTICE_COLLECTION_SHARED',
}
