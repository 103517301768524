import ModalRoot from 'components/modals/ModalRoot'
import MainNav from 'components/navigation/MainNav'
import NoticeRoot from 'components/notices/NoticeRoot'
import MobileBlocker from 'components/layout/MobileBlocker'

export default function Layout({ className = '', children }) {
  return (
    <main className={`layout flex ${className}`}>
      <MainNav />
      <div className="layout__content">{children}</div>
      <ModalRoot />
      <NoticeRoot />
      <MobileBlocker />
    </main>
  )
}
