import './api'
import axios from 'axios'

export const accountService = {
  getSelf,
  updateConfig,
}

async function getSelf() {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/account/me`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateConfig(data) {
  const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/account/config`, JSON.stringify(data))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}
