import { Link, NavLink } from 'react-router-dom'
import { Logo, IconLibrary, IconFeeds, IconWidgets, IconFile, IconHome } from '_svg'

export default function MainNav() {
  return (
    <nav className="main-nav">
      <Link to="/" className="logo">
        <Logo />
      </Link>
      <ul className="list-reset">
        <li className="">
          <NavLink activeClassName="is-active" to="/" className="main-nav__item" exact>
            <IconHome className="main-nav__icon" />
            <span>Accueil</span>
          </NavLink>
        </li>
        <li className="mt-10">
          <NavLink activeClassName="is-active" to="/feed" className="main-nav__item">
            <IconFeeds className="main-nav__icon" />
            <span>Feed</span>
          </NavLink>
        </li>
        <li className="mt-10">
          <NavLink activeClassName="is-active" to="/messages" className="main-nav__item">
            <IconFeeds className="main-nav__icon" />
            <span>Messages de demande de droits</span>
          </NavLink>
        </li>
        <li className="mt-10">
          <NavLink activeClassName="is-active" to="/library" className="main-nav__item">
            <IconLibrary className="main-nav__icon" />
            <span>Bibliothèque</span>
          </NavLink>
        </li>
        <li className="mt-10">
          <NavLink activeClassName="is-active" to="/collections" className="main-nav__item">
            <IconFile className="main-nav__icon" />
            <span>Collections</span>
          </NavLink>
        </li>
        <li className="mt-10">
          <NavLink activeClassName="is-active" to="/widgets" className="main-nav__item">
            <IconWidgets className="main-nav__icon icon icon--grey-light" />
            <span>Widgets</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}
