import axios from 'axios'
import './api'

export const messagesService = {
  getAllMessages,
  createMessage,
  updateMessage,
  deleteMessage,
}

/****************
// MESSAGES
****************/

async function getAllMessages() {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/message`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function createMessage(item) {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/message`, JSON.stringify(item))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteMessage(id) {
  const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/message/${id}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateMessage(item) {
  const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/message`, JSON.stringify(item))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}
