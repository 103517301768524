import { modalsConstants } from '_constants'

export const modalActions = {
  closeModal,
  deleteFromLibrary,
  showMediaDetails,
  handleLinkProduct,
  handleAuthorName,
  createCollection,
  deleteCollection,
  addToCollection,
  downloadToCollection,
  deleteFromCollection,
  rejectGroupedFromFeed,
  rejectFromFeed,
  deleteFeed,
  deleteFeeds,
  createMessage,
  deleteMessage,
  updateMessage,
  createUser,
  deleteUser,
  updateUser,
  deleteInstagram,
  askMessage,
  changePassword,
  chooseCollection,
  deleteWidget,
  addFeedMediaManually,
  shareCollection,
}

function closeModal() {
  return (dispatch) => {
    dispatch({ type: modalsConstants.CLOSE_MODAL })
  }
}

/*
 * LIBRARY
 */

function deleteFromLibrary(assets) {
  return (dispatch) => {
    dispatch(action({ assets }))
  }

  function action(assets) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_FROM_LIBRARY,
      modalProps: assets,
    }
  }
}

function showMediaDetails(asset) {
  return (dispatch) => {
    dispatch(action({ asset }))
  }

  function action(asset) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.SHOW_MEDIA_DETAILS,
      modalProps: asset,
    }
  }
}

function handleLinkProduct(asset) {
  return (dispatch) => {
    dispatch(action({ asset }))
  }

  function action(asset) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.LINK_TO_PRODUCT,
      modalProps: asset,
    }
  }
}

function handleAuthorName(asset) {
  return (dispatch) => {
    dispatch(action({ asset }))
  }

  function action(asset) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.AUTHOR_NAME,
      modalProps: asset,
    }
  }
}

/*
 * COLLECTIONS
 */

function createCollection(back = false, mediaID = null) {
  return (dispatch) => {
    dispatch(action(back, mediaID))
  }

  function action(back) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.CREATE_COLLECTION,
      modalProps: { canGoBack: back, mediaID: mediaID },
    }
  }
}

function deleteCollection(collection) {
  return (dispatch) => {
    dispatch(action(collection))
  }

  function action(collection) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_COLLECTION,
      modalProps: { collection },
    }
  }
}

function addToCollection(media) {
  return (dispatch) => {
    dispatch(action({ media }))
  }

  function action(media) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.ADD_TO_COLLECTION,
      modalProps: media,
    }
  }
}

function downloadToCollection(media) {
  return (dispatch) => {
    dispatch(action({ media }))
  }

  function action(media) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DOWNLOAD_TO_COLLECTION,
      modalProps: media,
    }
  }
}

function shareCollection(collection) {
  return (dispatch) => {
    dispatch(action({ collection }))
  }

  function action(modalProps) {
    return {
      modalProps,
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.SHARE_COLLECTION,
    }
  }
}

function deleteFromCollection(collection, assets) {
  return (dispatch) => {
    dispatch(action({ collection, assets }))
  }

  function action(data) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_FROM_COLLECTION,
      modalProps: data,
    }
  }
}

/*
 * FEEDS
 */

function rejectFromFeed(media, feedID, callback) {
  return (dispatch) => {
    dispatch(action({ media, feedID, callback }))
  }

  function action(data) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.REJECT_FROM_FEED,
      modalProps: { ...data },
    }
  }
}

function rejectGroupedFromFeed(groupedAssets, callback) {
  return (dispatch) => {
    dispatch(action({ groupedAssets, callback }))
  }

  function action(data) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.REJECT_FROM_FEED,
      modalProps: { ...data },
    }
  }
}

function addFeedMediaManually(feedID, callback) {
  return (dispatch) => {
    dispatch(action({ feedID, callback }))
  }

  function action(data) {
    return {
      modalProps: { ...data },
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.ADD_FEED_MEDIA_MANUALLY,
    }
  }
}

function deleteFeed(feed) {
  return (dispatch) => {
    dispatch(action(feed))
  }

  function action(id) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_FEED,
      modalProps: { feed },
    }
  }
}

function deleteFeeds(feeds, loadFeeds) {
  return (dispatch) => {
    dispatch(action(feeds, loadFeeds))
  }

  function action(feeds, loadFeeds) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_FEEDS,
      modalProps: { feeds, loadFeeds },
    }
  }
}

function createMessage() {
  return (dispatch) => {
    dispatch(action())
  }

  function action() {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.CREATE_MESSAGE,
      modalProps: {},
    }
  }
}

function deleteMessage(id) {
  return (dispatch) => {
    dispatch(action(id))
  }

  function action(id) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_MESSAGE,
      modalProps: { id },
    }
  }
}

function updateMessage(msg) {
  return (dispatch) => {
    dispatch(action(msg))
  }

  function action(msg) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.UPDATE_MESSAGE,
      modalProps: { msg },
    }
  }
}

function askMessage(media) {
  return (dispatch) => {
    dispatch(action(media))
  }

  function action(media) {
    return {
      modalProps: { media },
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.ASK_MESSAGE,
    }
  }
}

/*
 * ACCOUNT
 */

function changePassword(userId) {
  return (dispatch) => {
    dispatch(action(userId))
  }

  function action(userId) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.CHANGE_PASSWORD,
      modalProps: { userId },
    }
  }
}

function createUser() {
  return (dispatch) => {
    dispatch(action())
  }

  function action() {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.CREATE_USER,
      modalProps: {},
    }
  }
}

function deleteUser(user) {
  return (dispatch) => {
    dispatch(action(user))
  }

  function action(user) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_USER,
      modalProps: { user },
    }
  }
}

function deleteInstagram(creds) {
  return (dispatch) => {
    dispatch(action(creds))
  }

  function action(creds) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_CREDS,
      modalProps: { creds },
    }
  }
}

function updateUser(user) {
  return (dispatch) => {
    dispatch(action(user))
  }

  function action(user) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.UPDATE_USER,
      modalProps: { user },
    }
  }
}

/*
 * WIDGETS
 */

function chooseCollection() {
  return (dispatch) => {
    dispatch(action())
  }

  function action() {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.CHOOSE_COLLECTION,
      modalProps: {},
    }
  }
}

function deleteWidget(widget) {
  return (dispatch) => {
    dispatch(action(widget))
  }

  function action(widget) {
    return {
      type: modalsConstants.OPEN_MODAL,
      modalType: modalsConstants.DELETE_WIDGET,
      modalProps: { widget },
    }
  }
}
