import axios from 'axios'
import Utils from './Utils'
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem('user')).token
    if (accessToken) {
      // config.headers["x-auth-token"] = accessToken;
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    let refreshToken = JSON.parse(localStorage.getItem('user'))?.refresh_token
    if (!refreshToken && originalRequest?._retry) Utils.logout()

    switch (+error.response.status) {
      case 401:
        console.log(error.response)

        if (error.response.data.message === 'Expired JWT Token') {
          originalRequest._retry = true
          if (!refreshToken) return
          const refreshTokenReq = await axios.post(`${process.env.REACT_APP_API_URL}/api/token/refresh`, {
            refresh_token: refreshToken,
          })

          const newUser = { ...JSON.parse(localStorage.getItem('user')), ...refreshTokenReq.data }
          localStorage.setItem('user', JSON.stringify(newUser))
          return axios(originalRequest)
        } else {
          Utils.logout()
          return Promise.reject(error)
        }

      // case 400:
      default:
        return Promise.reject(error.response.data)
    }
  }
)
