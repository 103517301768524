import { IconPlusColor } from '_svg'
import { useSelector } from 'react-redux'
import React, { useEffect, useState, useRef } from 'react'
import HashtagListItem from 'components/_feeds/HashtagListItem'
import HashtagListGroup from 'components/_feeds/HashtagListGroup'

export default function HashtagsList({ title, limit = 0, theme = '', preselection = [], callback }) {
  const globalHTcount = useSelector((state) => state.feeds.hashtags_count)

  const inputHashtag = useRef([])
  const hashtagList = useRef([])

  const [hashtags, setHashtags] = useState([])
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (preselection.length > 0 && preselection.length !== hashtags.length) setHashtags(preselection)
  }, [preselection, hashtags])

  useEffect(() => {
    if(hashtags.length < 5) {
      setExpanded(false)
    }
  }, [hashtags])

  const addHashtag = () => {
    // prevent empty value:
    if (inputHashtag.current.value === '' || inputHashtag.current.value.lengt <= 1) return
    if (limit > 0 && hashtags.length >= limit) return
    //remove space from value:

    // let value = inputHashtag.current.value.replace(/\s/g, '')  
    let value = inputHashtag.current.value

    // remove #

    // while (value.charAt(0) === '#') {
    //   value = value.substring(1)
    // }

    // check if already exists
    if (!hashtags.includes(value)) {
      const temp = [...hashtags, value]
      setHashtags(temp)
      if (callback) callback(temp)
    }
    // empty field
    inputHashtag.current.value = ''
  }

  const deleteHashtag = (el) => {
    const temp = hashtags.filter((h) => {
      return h !== el.innerText
    })
    setHashtags(temp)
    if (callback) callback(temp)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault()
      if (e.target.value !== '') addHashtag()
    }
  }

  const getHashtagsList = () => {
    return hashtags.reverse().filter(el => el !== "").map((el, i) => {
      if (hashtags.length > 5 && i === 4 && expanded === false) {
        return <HashtagListGroup cpt={hashtags.length - 4} setExpanded={setExpanded} />
      } else if (hashtags.length > 5 && i > 4) {
        return <>{expanded ? <HashtagListItem key={i} name={`${el}`} deleteFunction={deleteHashtag} /> : <></>}</>
      } else if (i < 5) {
        return <HashtagListItem key={i} name={`${el}`} deleteFunction={deleteHashtag} />
      }
    })
  }

  return (
    <div className={`hashtags-list ${theme}`}>
      <label htmlFor="" className="label">
        {title}
      </label>
      <div className="input-add">
        <input
          ref={inputHashtag}
          type="text"
          name="hastags"
          id=""
          className="text-input"
          placeholder=""
          onKeyDown={handleKeyDown}
        />
        {(limit === 0 || limit - hashtags.length > 0) && (
          <div
            className="bt-add-hashtag"
            onClick={() => {
              addHashtag()
            }}
          >
            <IconPlusColor className="icon" />
          </div>
        )}
      </div>
      {limit > 0 && (
        <div className={`counter ${hashtags.length >= limit ? 'red' : ''}`}>
          Encore {limit - hashtags.length} hashtag{limit - hashtags.length > 1 ? 's' : ''}{' '}
          {/* <span>
            - {limit} maximum ({30 - globalHTcount}/30 encore disponibles cette semaine)
          </span> */}
        </div>
      )}
      <div className="hashtags-list flex flex-wrap" ref={hashtagList}>
        {getHashtagsList()}
      </div>
    </div>
  )
}
