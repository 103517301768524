import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { libraryActions } from '_actions'
import Button from 'components/buttons/Button'

export default function LinkToProduct({ ...modalProps }) {
  const [assetData, setAssetData] = useState({
    ...modalProps.asset[0],
    productTitle: modalProps.asset[0].productTitle ?? '',
    productDesc: modalProps.asset[0].productDesc ?? '',
    productImgLink: modalProps.asset[0].productImgLink ?? '',
    productLink: modalProps.asset[0].productLink ?? '',
  })
  const dispatch = useDispatch()

  const submitForm = (e) => {
    e.preventDefault()
    dispatch(libraryActions.updateAsset(assetData))
  }

  const handleProductTitleChange = (e) => {
    setAssetData({ ...assetData, productTitle: e.target.value })
  }

  const handleProductDescChange = (e) => {
    setAssetData({ ...assetData, productDesc: e.target.value })
  }

  const handleProductImgLinkChange = (e) => {
    setAssetData({ ...assetData, productImgLink: e.target.value })
  }

  const handleProductLinkChange = (e) => {
    setAssetData({ ...assetData, productLink: e.target.value })
  }

  return (
    <div className="modal-create-message">
      <div className="modal__title">Lier un produit à l'asset</div>
      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <div className="label label--med">Titre du produit{}</div>
        <input
          type="text"
          className="input-text-grey"
          onChange={handleProductTitleChange}
          value={assetData.productTitle}
          placeholder="Ajouter un titre"
        />
        <div className="label label--med">Description du produit{}</div>
        <textarea
          type="text"
          className="input-text-grey"
          onChange={handleProductDescChange}
          value={assetData.productDesc}
          style={{border: "none"}}
          placeholder="Ajouter une description"
        />
        <div className="label label--med">Lien de l'image{}</div>
        <input
          type="text"
          className="input-text-grey"
          onChange={handleProductImgLinkChange}
          value={assetData.productImgLink}
          placeholder="Ajouter un lien vers l'image (Format : jpg ou png)"
        />
        <div className="label label--med">Lien de la fiche produit{}</div>
        <input
          type="text"
          className="input-text-grey"
          onChange={handleProductLinkChange}
          value={assetData.productLink}
          placeholder="Ajouter un lien vers la fiche produit"
        />
        {assetData.productTitle.length > 1 &&
          assetData.productDesc.length > 1 &&
          assetData.productImgLink.length > 1 &&
          assetData.productLink.length > 1 && (
            <Button btnStyle="plain" type="submit" addedclass="self-end mt-6">
              {modalProps.msg ? 'Modifier' : 'Sauvegarder'}
            </Button>
          )}
      </form>
    </div>
  )
}
