import Layout from 'components/layout/Layout'
import { useParams } from 'react-router-dom'
import Button from 'components/buttons/Button'
import { IconDownload, IconDelete } from '_svg'
import CheckBox from 'components/filters/CheckBox'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from 'components/navigation/Pagination'
import React, { useEffect, useState, useCallback } from 'react'
import { collectionsActions, modalActions, noticesActions } from '_actions'
import CollectionMediaListItem from 'components/_collections/CollectionMediaListItem'

function CollectionsContents() {
  const collectionsRdx = useSelector((state) => state.collections)
  // const collections = useSelector(state => state.collections.items);
  const [isLoading, setIsLoading] = useState(false)
  const [collection, setCollection] = useState(null)
  const [assets, setAssets] = useState([])
  const [selectedAssets, setSelectedAssets] = useState([])
  const [pagination, setPagination] = useState({
    current_page: 1,
    limit: 20,
    total_count: 0,
  })
  // pagnation & filters
  const [requestOptions, setRequestOptions] = useState({
    q: '',
    page: 1,
    limit: 20,
    // media_type: 'IMAGE,VIDEO,SIDECAR',
    sort_by: 'created_at',
    sort_order: 'DESC',
  })

  const urlParams = useParams()

  const dispatch = useDispatch()

  const loadCollections = useCallback(() => {
    dispatch(collectionsActions.getCollections())
  }, [dispatch])

  const defineCollection = useCallback(
    (collection) => {
      const myCollection = collectionsRdx.items.find((collection) => collection.id === urlParams.id)
      // setSelectedAssets([])
      return { ...collection, ...myCollection }
    },
    [collectionsRdx, urlParams]
  )

  const loadAssets = useCallback(() => {
    setIsLoading(true)
    setSelectedAssets([])
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const collectionID = collection ? collection.id : urlParams.id
    dispatch(collectionsActions.getAssets(collectionID, requestOptions))
  }, [dispatch, collection, urlParams, requestOptions])

  useEffect(() => {
    if (collectionsRdx.items.length <= 0) {
      loadCollections()
    } else {
      setCollection(defineCollection)
    }
    setIsLoading(false)
  }, [collectionsRdx, loadCollections, defineCollection])

  useEffect(() => {
    if (!collection) return
    if (!collection.assets) {
      loadAssets()
    } else {
      if (collection.pagination !== pagination) setPagination(collection.pagination)
      setAssets(collection.assets)
    }
  }, [collection, loadAssets, pagination])

  useEffect(() => {
    // condition te prevent loading on first loading
    if (!collection || !collection.assets) return
    loadAssets()
  }, [requestOptions])

  const handlePagination = (index) => {
    const temp = {
      page: index,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const selectAsset = (asset, isChecked) => {
    if (isChecked) {
      setSelectedAssets([...selectedAssets, asset])
    } else {
      const newSelectedAssets = selectedAssets.filter((a) => {
        return a.id !== asset.id
      })
      setSelectedAssets(newSelectedAssets)
    }
  }

  const selectAllAssets = (el) => {
    const selectedAssets = []
    if (el.checked) {
      assets.forEach((el) => {
        selectedAssets.push(el)
      })
    }
    setSelectedAssets(selectedAssets)
  }

  const groupDelete = (e) => {
    dispatch(modalActions.deleteFromCollection(collection, selectedAssets))
    setSelectedAssets([])
  }

  const groupDownload = () => {
    download(selectedAssets)
    setSelectedAssets([])
  }

  const download = (elArray) => {
    if (elArray.length > 0) {
      dispatch(noticesActions.download(elArray))
    }
  }

  const getContentsList = () => {
    return assets.map((el, i) => {
      return (
        <div className="stagger-anim" key={i}>
          <CollectionMediaListItem data={el} selectFunction={selectAsset} isSelected={selectedAssets.includes(el)} />
        </div>
      )
    })
  }

  return (
    <Layout className="page page-collections-contents">
      <PageHeader
        data={[
          { label: 'Collections', url: '/collections' },
          { label: collection ? collection.name : '' },
        ]} /*  tabs={[{label: 'Toute la bibliothèque', url:`/collections`}, {label: 'Collections', url:`/collections`}]} */
      />
      <div className="page__content">
        <div className="sticky-wrapper">
          <div className="flex justify-between items-end">
            <CheckBox
              name="action"
              label="Tout selectionner"
              isChecked={selectedAssets.length > 0 && selectedAssets.length === assets.length}
              groupAction={true}
              callback={selectAllAssets}
            />
            {pagination && (
              <span className="assets-total-count">{`${pagination.total_count} Média${
                pagination.total_count > 1 ? 's' : ''
              }`}</span>
            )}
            <div className="flex flex-nowrap items-center"></div>
          </div>
        </div>
        <div className={`collection-medias-list flex flex-wrap ${isLoading ? 'is-loading' : ''}`}>
          {getContentsList()}
        </div>
        {pagination && <Pagination data={pagination} requestFn={handlePagination} />}
      </div>
      <div className={`grouped-actions ${selectedAssets.length > 0 ? 'is-active' : ''}`}>
        <div className="grouped-actions__counter">
          Vous avez sélectionné{' '}
          <span className="text-gradient">
            {selectedAssets.length} Média{selectedAssets.length > 1 && 's'}
          </span>
        </div>
        <div className="grouped-actions__ctas">
          <Button
            btnStyle="border"
            color="grey-lighter"
            type={null}
            addedclass="button-icon"
            onClickFn={() => groupDownload()}
          >
            <IconDownload className="icon" />
            Télécharger les contenus
          </Button>
          <Button onClickFn={groupDelete} btnStyle="border" color="grey-lighter" type={null} addedclass="button-icon">
            <IconDelete className="icon" />
            Supprimer de la collection
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default CollectionsContents
